export const UPDATE_SETTINGS_DATA = "UPDATE_SETTINGS_DATA";
export const UPDATE_SETTINGS_CITIES_LIST = "UPDATE_SETTINGS_CITIES_LIST";
export const UPDATE_SETTINGS_KITCHENS_LIST = "UPDATE_SETTINGS_KITCHENS_LIST";
export const UPDATE_SETTINGS_LOCATIONS_LIST = "UPDATE_SETTINGS_LOCATIONS_LIST";
export const UPDATE_SETTINGS_PAYMENT_TYPES = "UPDATE_SETTINGS_PAYMENT_TYPES";
export const UPDATE_SETTINGS_ORDER_STATUSES = "UPDATE_SETTINGS_ORDER_STATUSES";
export const UPDATE_SETTINGS_ORDER_TYPES = "UPDATE_SETTINGS_ORDER_TYPES";
export const UPDATE_SETTINGS_ITEMS_STATUSES = "UPDATE_SETTINGS_ITEMS_STATUSES";
export const UPDATE_SETTINGS_PRODUCT_CATEGORIES = "UPDATE_SETTINGS_PRODUCT_CATEGORIES";
export const UPDATE_SETTINGS_PRODUCT_TYPES = "UPDATE_SETTINGS_PRODUCT_TYPES";
export const UPDATE_SETTINGS_ROLES_LIST = "UPDATE_SETTINGS_ROLES_LIST";
export const UPDATE_KITCHEN_FORM = "UPDATE_KITCHEN_FORM";
export const UPDATE_KITCHEN_FORM_SHIFT_FIRST = "UPDATE_KITCHEN_FORM_SHIFT_FIRST";
export const UPDATE_KITCHEN_FORM_SHIFT_LAST = "UPDATE_KITCHEN_FORM_SHIFT_LAST";
export const SET_KITCHEN = "SET_KITCHEN";
export const UPDATE_SETTINGS_RESTAURANS_LIST = "UPDATE_SETTINGS_RESTAURANS_LIST";
export const UPDATE_RESTAURANT_FORM = "UPDATE_RESTAURANT_FORM";
export const SET_RESTORANT = "SET_RESTORANT";
export const UPDATE_CITY_FORM = "UPDATE_CITY_FORM";
export const GET_CITY = "GET_CITY";
export const CLEANING_NEW_FORM = "CLEANING_NEW_FORM";
export const UPDATE_LOCATION_FORM = "UPDATE_LOCATION_FORM";
export const GET_LOCATION = "GET_LOCATION";
export const UPDATE_ROLE_FORM = "UPDATE_ROLE_FORM";
export const GET_ROLE = "GET_ROLE";
export const UPDATE_ROLE_FORM_PAGES = "UPDATE_ROLE_FORM_PAGES";
export const UPDATE_FORM_PROMOTION = "UPDATE_FORM_PROMOTION";
export const UPDATE_FORM_PROMOTION_WEEK = "UPDATE_FORM_PROMOTION_WEEK";
export const UPDATE_FORM_PROMOTION_WINDOW = "UPDATE_FORM_PROMOTION_WINDOW";
export const GET_PROMOTION = "GET_PROMOTION";
export const GET_PROMOTIO_ITEM = "GET_PROMOTIO_ITEM";
export const GET_PROMOCODE = "GET_PROMOCODE";
export const UPDATE_FORM_PROMOCODE_WINDOW = "UPDATE_FORM_PROMOCODE_WINDOW";
export const UPDATE_FORM_PROMOCODE = "UPDATE_FORM_PROMOCODE";
export const GET_PROMOCODE_ITEM = "GET_PROMOCODE_ITEM";
export const UPDATE_FORM_PROMOCODE_ARRAY = "UPDATE_FORM_PROMOCODE_ARRAY";
export const UPDATE_FORM_RESOURCE = "UPDATE_FORM_RESOURCE";
export const GET_RESOURCE_LIST = "GET_RESOURCE_LIST";
export const GET_RESOURCE_ID = "GET_RESOURCE_ID";
export const GET_PAYMENT_LIST = "GET_PAYMENT_LIST";
export const UPDATE_PAYMENT_FORM = "UPDATE_PAYMENT_FORM";
export const GET_PAYMENT_ID = "GET_PAYMENT_ID";
export const DELETE_ONE_FIELD_PROMOCODE = "DELETE_ONE_FIELD_PROMOCODE";
export const ADD_ONE_FIELD_PROMOCODE = "ADD_ONE_FIELD_PROMOCODE";
export const UPDATE_ONE_FIELD_CODE_PROMOCODE = "UPDATE_ONE_FIELD_CODE_PROMOCODE";
export const ADD_ONE_FIELD_CODE_PROMOCODE = "ADD_ONE_FIELD_CODE_PROMOCODE";
export const DELETE_ONE_FIELD_CODE_PROMOCODE = "DELETE_ONE_FIELD_CODE_PROMOCODE";
export const UPDATE_FORM_PICKUP = "UPDATE_FORM_PICKUP";
export const GET_FORM_PICKUP = "GET_FORM_PICKUP";
export const DELETE_CITY_BY_ID = "DELETE_CITY_BY_ID";
export const UPDATE_CITY_STREET_BY_ID = "UPDATE_CITY_STREET_BY_ID";
export const ADD_STREET_BY_CITY = "ADD_STREET_BY_CITY";
export const UPDATE_FORM_TYPE_OF_DELIVERY = "UPDATE_FORM_TYPE_OF_DELIVERY";
export const GET_TYPES_OF_DELIVERY_LIST = "GET_TYPES_OF_DELIVERY_LIST";
export const GET_DELIVERY_ID = "GET_DELIVERY_ID";
export const UPDATE_FORM_REASON = "UPDATE_FORM_REASON";
export const GET_REASONS_LIST = "GET_REASONS_LIST";
export const GET_REASON_ID = "GET_REASON_ID";
export const CLEANING_NEW_FORM_OBJ = "CLEANING_NEW_FORM_OBJ";
export const GET_BUG_REPORT_ALL = "GET_BUG_REPORT_ALL";
export const SELECT_BUG_REPORT_TO_VIEW = "SELECT_BUG_REPORT_TO_VIEW";
export const UPDATE_BUG_REPORT_LOAD_PAGE = "UPDATE_BUG_REPORT_LOAD_PAGE";
export const UPDATE_BUG_REPORT_LOAD_MODAL = "UPDATE_BUG_REPORT_LOAD_MODAL";
export const GET_COMPLAINT_ALL = "GET_COMPLAINT_ALL";
export const UPDATE_COMPLAINT_LOAD_PAGE = "UPDATE_COMPLAINT_LOAD_PAGE";
export const UPDATE_COMPLAINT_LOAD_MODAL = "UPDATE_COMPLAINT_LOAD_MODAL";
export const GET_COMPLAINT_ONE = "GET_COMPLAINT_ONE";
export const UPDATE_COMPLAINT_FORM = "UPDATE_COMPLAINT_FORM";
export const COMPLAINT_FORM_CLEAN = "COMPLAINT_FORM_CLEAN";
export const GET_COMPLAINT_ALL_SOURCES = "GET_COMPLAINT_ALL_SOURCES";
export const UPDATE_COMPLAINT_LOAD_PAGE_SOURCES = "UPDATE_COMPLAINT_LOAD_PAGE_SOURCES";
export const UPDATE_COMPLAINT_LOAD_MODAL_SOURCES = "UPDATE_COMPLAINT_LOAD_MODAL_SOURCES";
export const GET_COMPLAINT_ONE_SOURCES = "GET_COMPLAINT_ONE_SOURCES";
export const UPDATE_COMPLAINT_FORM_SOURCES = "UPDATE_COMPLAINT_FORM_SOURCES";
export const COMPLAINT_FORM_CLEAN_SOURCES = "COMPLAINT_FORM_CLEAN_SOURCES";
export const UPDATE_SETTINGS_PRODUCT_CATEGORIES_FORM = "UPDATE_SETTINGS_PRODUCT_CATEGORIES_FORM";
export const GET_ONE_SETTINGS_PRODUCT_CATEGORIE = "GET_ONE_SETTINGS_PRODUCT_CATEGORIE";
export const UPDATE_TELESTAT_FORM = "UPDATE_TELESTAT_FORM";
export const GET_TELESTATE_FORM = "GET_TELESTATE_FORM";
export const GET_TRANSPORT = "GET_TRANSPORT";
export const GET_SETTINGS_CASHIER = "GET_SETTINGS_CASHIER";
export const GET_SETTINGS_METOD_PAYMENTS = "GET_SETTINGS_METOD_PAYMENTS";

export const SET_SETTINGS_POLIGONES = "SET_SETTINGS_POLIGONES";