import { useEffect, useState } from "react";

import ButtonIcon from "components/ui-kit/ButtonIcon";
import Table from "components/ui-kit/Table";
import Checkbox from "components/ui-kit/Checkbox";

import { MenuItem } from "components/menuPageMulti/menuItem";
import { LoadingCircl } from "components/ui-kit/LoadingScreen/LoadingCircl";
import ModalDelete from "components/modals/ModalDelete";
import { LoadingModal } from "components/loadingOrder/loading";
import { EditPromocode } from "./EditPromocode";

import AddIcon from "@mui/icons-material/Add";

import styles from "../scss/settings.module.scss";
import promoStyles from "./Promocodes.module.scss";
import classnames from "classnames";

import { tablePromocodes } from "constants/tables";

const Promocodes = ({
    //state
    promocodes,
    promocodesEdit,
    promocodesFilter: { 
        promo_type: promoType, 
        status: promoStatus,
    },
    isOpenModalDelete,
    isLoadModalDelete,
    categories,
    products,
    restaurants,
    // dispatch
    getPromocode,
    getPromocodeId,
    updateFormPromocode,
    updateFormPromocodeWindow,
    createPromocode,
    updatePromocode,
    deletePromocode,
    updateModalDelete,
    updateFormPromocodeArray,
    deleteOneFieldPromocode,
    addOneFieldPromocode,
    updateFormPromocodeItemCode,
    addOneFieldCodePromocode,
    deleteOneFieldCodePromocode,
    setPromosFilter,
    getProducts
}) => {
    const {
        isOpen,
        isLoad,
        isLoadEdit,
        data,
    } = promocodesEdit;

    const [idDeletePromocode, setIdDeletePromocode] = useState(undefined);

    useEffect(() => {
        getPromocode();
        updateFormPromocodeWindow('isOpen', false);
    }, []);

    const handlerCreatePromocode = () => {
        updateFormPromocodeWindow('isOpen', true, true);
    }

    const handlerEditPromocodes = ({ id }) => {
        getPromocodeId(id);
        updateFormPromocodeWindow('isOpen', true);
    }

    const handleClosePromocodes = () => {
        updateFormPromocodeWindow('isOpen', false);
    }

    const handleSubmitСreate = () => {
        createPromocode();
    }

    const handleSubmitUpdate = () => {
        updatePromocode();
    }

    const onDelete = ({ id }) => {
        updateModalDelete('isOpen', true);
        setIdDeletePromocode(id);
    }

    const handleDeletePromocodes = () => {
        deletePromocode(idDeletePromocode);
    }

    return (
        <div>
            <div className={classnames(styles.wr_head, promoStyles.head)}>
                <div className={classnames(styles.d_max_1023, promoStyles.spacebetween)}>
                    <ul className={promoStyles.itemlist}>
                        <MenuItem
                            inKey="promocode"
                            title="Промокоди"
                            onHandle={setPromosFilter.bind(this, "promo_type")}
                            isActive={promoType === "promocode"}
                        />
                        <MenuItem
                            inKey="promoaction"
                            title="Промо-акції"
                            onHandle={setPromosFilter.bind(this, "promo_type")}
                            isActive={promoType === "promoaction"}
                        />
                        <MenuItem
                            title="Всі"
                            onHandle={setPromosFilter.bind(this, "promo_type")}
                            isActive={!promoType}
                        />
                    </ul>
                    <Checkbox 
                        isStyle={true} 
                        isMin={true} 
                        title="Відобразити неактивні" 
                        checked={!promoStatus} 
                        onChecked={setPromosFilter.bind(this, "status", +(!promoStatus) || undefined )} 
                    />
                </div>
                <div className={promoStyles.leftmargin}>
                    <ButtonIcon
                        onClick={handlerCreatePromocode}
                        icon={<AddIcon />}
                        positionIcon="left"
                    />
                </div>
            </div>
            <div className={`${styles.row_mrt} ${styles.d_max_1023}`}>
                <div className={`${styles.wr_row}`}>
                    {
                        !isLoad ? (
                            promocodes.length > 0 ? (
                                <Table
                                    onClick={handlerEditPromocodes}
                                    onDelete={onDelete}
                                    data={promocodes}
                                    scheme={tablePromocodes}
                                />
                            ) : (
                                <div className={styles.text_info}>Пусто... Немає промокодів</div>
                            )
                        ) : (
                            <LoadingCircl />
                        )
                    }
                </div>
                {
                    isOpen && (
                        <div className={`${styles.row_mrt}`}>
                            <EditPromocode
                                categories={categories}
                                promocodesEdit={data}
                                handleSubmitClose={handleClosePromocodes}
                                updateFormPromocode={updateFormPromocode}
                                handleSubmitСreate={handleSubmitСreate}
                                handleSubmitUpdate={handleSubmitUpdate}
                                updateFormPromocodeArray={updateFormPromocodeArray}
                                deleteOneFieldPromocode={deleteOneFieldPromocode}
                                addOneFieldPromocode={addOneFieldPromocode}
                                updateFormPromocodeItemCode={updateFormPromocodeItemCode}
                                addOneFieldCodePromocode={addOneFieldCodePromocode}
                                deleteOneFieldCodePromocode={deleteOneFieldCodePromocode}
                                products={products}
                                restaurants={restaurants}
                                getProducts={getProducts}
                            />
                            {
                                isLoadEdit && (
                                    <LoadingModal />
                                )
                            }
                        </div>
                    )
                }
            </div>

            <ModalDelete
                title={'Видалити'}
                text={`Ви підтверджуєте видалення ?`}
                isOpen={isOpenModalDelete}
                isLoad={isLoadModalDelete}
                funDelete={() => handleDeletePromocodes()}
                updateModalDelete={updateModalDelete}
            />
        </div>
    )
}

export default Promocodes;