import * as React from "react";

import { useState } from "react";
import ButtonIcon from "components/ui-kit/ButtonIcon";
import { Dropdown } from "components/ui-kit/inputs/Dropdown";

import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from '@mui/icons-material/Close';
import styles from "../scss/settings.module.scss";

import {
    validatePromotion,
    validatePromotionTypePickup
} from "utils/validation";

import { PromotionTypeN } from "./PromotionTypeN";
import { PromotionTypePickup } from "./PromotionTypePickup";
import { PromotionTypeLateCourierExcuse } from "./PromotionTypeLateCourierExcuse";

import {
    typePromotions,
    n_plus_one,
    pickup_for_employees,
    late_courier_excuse,
} from "constants/promotions";

export const EditPromotion = ({
    //props
    roles,
    cities,
    categories,
    restaurants,
    promotions,
    products,
    promotionsEdit,
    handleSubmitСreate = () => { },
    handleSubmitUpdate = () => { },
    handleSubmitClose = () => { },
    updateFormPromotion = () => { },
    updateFormPromotionWeek = () => { },
    getProducts = () => { },
}) => {
    let title = promotionsEdit?.id ? `Редагування ${promotionsEdit.name}` : "Створення нової акції";
    let titleButton = promotionsEdit?.id ? 'Змінити' : 'Додати';

    const saveButtonDisabled = 
        promotionsEdit.type === late_courier_excuse && 
        !promotionsEdit?.id && // when not editing
        promotions.map(p => p.type).includes(late_courier_excuse);
    
    const [errorsTypeN, setErrorsTypeN] = useState({
        name: "",
        product_count: "",
        type: "",
        description: "",
        product_count: "",
        product_category_id: "",
        city_id: "",
    });

    const [errorsTypePickup, setErrorsTypePickup] = useState({
        name: "",
        product_count: "",
        type: "",
        description: "",
        product_count: "",
        product_category_id: "",
        city_id: "",
        sale: "",
    });

    const onSubmit = () => {
        switch (promotionsEdit.type) {
            case n_plus_one: {
                validatePromotion({
                    data: {
                        ...promotionsEdit
                    },

                    onSuccess: async () => {
                        if (promotionsEdit?.id) {
                            handleSubmitUpdate(promotionsEdit?.id);
                        } else {
                            handleSubmitСreate();
                        }

                        setErrorsTypeN({
                            name: "",
                            type: "",
                            description: "",
                            product_count: "",
                            product_category_id: "",
                            city_id: "",
                        });
                    },
                    onError: (validationErrors) => {
                        setErrorsTypeN(validationErrors);
                    },
                });
                break;
            }
            case late_courier_excuse: {
                if (promotionsEdit?.id) {
                    handleSubmitUpdate(promotionsEdit?.id);
                } else {
                    handleSubmitСreate();
                }
                break;
            }
            case pickup_for_employees: {
                validatePromotionTypePickup({
                    data: {
                        ...promotionsEdit
                    },

                    onSuccess: async () => {
                        if (promotionsEdit?.id) {
                            handleSubmitUpdate(promotionsEdit?.id);
                        } else {
                            handleSubmitСreate();
                        }

                        setErrorsTypePickup({
                            name: "",
                            type: "",
                            description: "",
                            product_category_id: "",
                            city_id: "",
                            sale: "",
                        });
                    },
                    onError: (validationErrors) => {
                        setErrorsTypePickup(validationErrors);
                    },
                });
                break;
            }
        }
    }

    const availablePromotionTypes = React.useMemo(() => {
        return typePromotions.map(p => ({
            ...p,
            disabled: (
                p.value === late_courier_excuse && 
                promotions.map(p => p.type).includes(late_courier_excuse)
            )
        }))
    }, [typePromotions, promotions]);

    return (
        <div>
            <div className={`${styles.title_row} ${styles.marg_bot}`}>{title}</div>
            <div className={`${styles.row_form}`}>
                <div className={`${styles.wr_drop} ${styles.w_all}`}>
                    <Dropdown
                        title='Тип акції'
                        isBlack={true}
                        width="100%"
                        onChange={(e) => updateFormPromotion("type", e.target.value)}
                        value={promotionsEdit.type}
                        list={availablePromotionTypes}
                        isLight={true}
                        fontSize={'14px'}
                        disabled={!!promotionsEdit.id}
                    />
                </div>
            </div>

            {
                promotionsEdit.type === "n_plus_one" && (
                    <PromotionTypeN
                        error={errorsTypeN}
                        roles={roles}
                        cities={cities}
                        categories={categories}
                        promotionsEdit={promotionsEdit}
                        updateFormPromotion={updateFormPromotion}
                        updateFormPromotionWeek={updateFormPromotionWeek}
                    />
                )
            }

            {
                promotionsEdit.type === "pickup_for_employees" && (
                    <PromotionTypePickup
                        error={errorsTypePickup}
                        roles={roles}
                        cities={cities}
                        categories={categories}
                        promotionsEdit={promotionsEdit}
                        updateFormPromotion={updateFormPromotion}
                    />
                )
            }

            {
                promotionsEdit.type === late_courier_excuse && (
                    <PromotionTypeLateCourierExcuse
                        // error={errorsTypeLateCourierExcuse}
                        restaurants={restaurants}
                        products={products}
                        promotionsEdit={promotionsEdit}
                        updateFormPromotion={updateFormPromotion}
                        getProducts={getProducts}
                    />
                )
            }

            <div className={styles.row_btn_tow}>
                <div className={styles.item_btn}>
                    <ButtonIcon
                        onClick={onSubmit}
                        title={titleButton}
                        colorBg="green"
                        icon={<SaveIcon />}
                        disabled={saveButtonDisabled}
                    />
                </div>
                <div className={styles.item_btn}>
                    <ButtonIcon
                        onClick={handleSubmitClose}
                        icon={<CloseIcon />}
                    />
                </div>
            </div>
        </div>
    )
}