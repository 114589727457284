import React, { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";

import history from "constants/history";

// import { MAINTENANCE } from "config";

import Navigation from "./navigation/Navigation";
import Notifications from "components/notifications";
// import MaintenanceAlert from "components/MaintenanceAlert";
import ModalConfirmLogout from "components/modals/ModalConfirmLogout";
import ModalBugReport from "components/modals/ModalBugReport";

const CommonLayout = ({
  children,
  user,
  tabs,
  openModalConfirmLogout,
  openModalBugReport,
}) => {
  const theme = useTheme();

  const [activeTab, setActiveTab] = useState(null);

  useEffect(
    () => setActiveTab(history?.location.pathname),
    [history?.location?.pathname]
  );

  return (
    <>
      <Navigation
        user={user}
        tabs={tabs}
        activeTab={activeTab}
        logout={openModalConfirmLogout}
        openModalBugReport={openModalBugReport}
      />

      {/* <MaintenanceAlert isOpen={MAINTENANCE} /> */}
      <Container theme={theme} $maintenance={false}>
        {children}
      </Container>

      <Notifications />
  
      <ModalConfirmLogout />
      <ModalBugReport />
    </>
  );
};

const Container = styled.section`
  padding: ${(p) => 58 + (p.$maintenance ? 35 : 0)}px 14px 14px 14px;
  min-height: calc(100vh - 50px);
`;

export default React.memo(CommonLayout);
