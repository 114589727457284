
import { Chip } from "@mui/material";
import { Code, Abc, Brush } from "@mui/icons-material";

const TemplateChip = ({
    title = '',
    template = '',
    handle = () => { },
    isLogical = false,
    isVisual = false,
}) => (
    <Chip
        label={title}
        icon={isVisual ? <Brush /> : isLogical ? <Code /> : <Abc />}
        variant={isLogical || isVisual ? "filled" : "outlined"}
        color={isVisual ? "primary" : isLogical ? "warning" : "default"}
        onClick={handle.bind(this, template)}
    />
);

export default TemplateChip;

