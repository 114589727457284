const condition = `
{% if ... %}
    Текст...
{% endif %}
`;

const iteration = `
{% for item, quantity in items %}
    {{ item }}: {{ quantity }}
{% endfor %}
`;

export const templates = { condition, iteration };
