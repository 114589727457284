import produce from "immer";

import { LOGOUT } from "../constants/session";
import {
  CLOSE_ALL_MODALS,
  CLOSE_MODAL,
  OPEN_MODAL,
  OPEN_EDITORCALLCENTER,
  CLOSE_EDITORCALLCENTER,
  OPEN_MODAL_FILTER,
  CLOSE_MODAL_FILTER,
  OPEN_MODAL_TOGGLE_KITCHEN_SHIFT,
  CLOSE_MODAL_TOGGLE_KITCHEN_SHIFT,
  OPEN_MODAL_BUG_REPORT,
  CLOSE_MODAL_BUG_REPORT,
  OPEN_MODAL_CONFIRM_LOGOUT,
  CLOSE_MODAL_CONFIRM_LOGOUT,
  IS_REDACTION_ORDER,
  IS_SET_ID_COOK,
  OPEN_MODAL_CLIENTS,
  CLOSE_MODAL_CLIENTS,
  IS_LOAD_MODAL_CLIENTS,
  UPDATE_LOAD_PAGE,
  OPEN_MODAL_CLIENTS_ADD,
  CLOSE_MODAL_CLIENTS_ADD,
  UPDATE_MODAL_COURIRERS,
  UPDATE_MODAL_DELETE,
  UPDATE_LOAD_MODAL_PRODUCTS,
  UPDATE_MODAL_STREET,
  UPDATE_LOAD_MODAL_USER,
  UPDATE_LOAD_BTN_RESOURCE,
  UPDATE_LOAD_EDIT_RESOURCE,
  UPDATE_LOAD_TABLE_RESOURCE,
  UPDATE_LOAD_EDIT_PAYMENT,
  UPDATE_ITEM_LOAD_BY_NAME
} from "../constants/view";

const initialState = {
  modals: [],
  modalCallCenter: {
    isOpen: false,
    data: null,
  },
  modalToggleKitchenShift: {
    isOpen: false,
  },
  modalBugReport: {
    isOpen: false,
  },
  modalConfirmLogout: {
    isOpen: false,
  },
  modalfiilter: {
    isOpen: false,
  },
  modalClients: {
    isOpen: false,
    loadingsModal: false,
    isOpenAdd: false,
  },
  modalListCouriers: {
    isOpen: false,
    loadingsModal: false,
  },
  modalDelete: {
    isOpen: false,
    loadingsModal: false,
  },
  modalStreet: {
    isOpen: false,
    loadingsModal: false,
  },
  modalStaticDetalis: {
    isOpen: false,
    loadingsModal: false,
  },
  isRedactionOrder: true,
  idLoadCook: null,
  isLoadPage: false,
  isLoadModalProduct: false,
  isLoadModalUser: false,
  isLoadTableResource: false,
  isLoadBtnResource: false,
  isLoadEditResource: false,
  isLoadEditPayment: false,
  isLoadEditDelivery: false,
  isLoadGeneral: false,
};

export default produce((draft, action) => {
  const { type, payload = {} } = action;

  switch (type) {
    case UPDATE_ITEM_LOAD_BY_NAME: {
      draft[payload.item] = payload.status;
    }
    case UPDATE_LOAD_EDIT_PAYMENT: {
      draft.isLoadEditPayment = payload;
      break;
    }

    case UPDATE_LOAD_TABLE_RESOURCE: {
      draft.isLoadTableResource = payload;
      break;
    }

    case UPDATE_LOAD_EDIT_RESOURCE: {
      draft.isLoadEditResource = payload;
      break;
    }

    case UPDATE_LOAD_BTN_RESOURCE: {
      draft.isLoadBtnResource = payload;
      break;
    }

    case UPDATE_LOAD_MODAL_USER: {
      draft.isLoadModalUser = payload;
      break;
    }

    case OPEN_MODAL:
      const { props = {} } = payload;

      draft.modals.unshift({
        createdAt: new Date(),
        props: { ...props },
        name: payload?.name,
      });
      break;

    case CLOSE_MODAL:
      draft.modals = payload.modals;
      break;

    case CLOSE_ALL_MODALS:
      draft.modals = [];
      break;

    case LOGOUT:
      draft.modals = [];
      break;

    case OPEN_EDITORCALLCENTER: {
      draft.modalCallCenter.isOpen = true;
      draft.modalCallCenter.data = payload;
      break;
    }

    case CLOSE_EDITORCALLCENTER: {
      draft.modalCallCenter.isOpen = false;
      draft.modalCallCenter.data = null;
      break;
    }

    case OPEN_MODAL_FILTER: {
      draft.modalfiilter.isOpen = true;
      break;
    }

    case CLOSE_MODAL_FILTER: {
      draft.modalfiilter.isOpen = false;
      break;
    }

    case OPEN_MODAL_TOGGLE_KITCHEN_SHIFT: {
      draft.modalToggleKitchenShift.isOpen = true;
      break;
    }

    case CLOSE_MODAL_TOGGLE_KITCHEN_SHIFT: {
      draft.modalToggleKitchenShift.isOpen = false;
      break;
    }

    case OPEN_MODAL_BUG_REPORT: {
      draft.modalBugReport.isOpen = true;
      break;
    }

    case CLOSE_MODAL_BUG_REPORT: {
      draft.modalBugReport.isOpen = false;
      break;
    }

    case OPEN_MODAL_CONFIRM_LOGOUT: {
      draft.modalConfirmLogout.isOpen = true;
      break;
    }

    case CLOSE_MODAL_CONFIRM_LOGOUT: {
      draft.modalConfirmLogout.isOpen = false;
      break;
    }

    case OPEN_MODAL_CLIENTS: {
      draft.modalClients.isOpen = true;
      break;
    }

    case CLOSE_MODAL_CLIENTS: {
      draft.modalClients.isOpen = false;
      break;
    }

    case OPEN_MODAL_CLIENTS_ADD: {
      draft.modalClients.isOpenAdd = true;
      break;
    }

    case CLOSE_MODAL_CLIENTS_ADD: {
      draft.modalClients.isOpenAdd = false;
      break;
    }

    case IS_LOAD_MODAL_CLIENTS: {
      draft.modalClients.loadingsModal = payload;
      break;
    }

    case IS_REDACTION_ORDER: {
      draft.isRedactionOrder = payload;
      break;
    }

    case IS_SET_ID_COOK: {
      draft.idLoadCook = payload.id;
      break;
    }

    case UPDATE_LOAD_PAGE: {
      draft.isLoadPage = payload;
      break;
    }

    case UPDATE_MODAL_COURIRERS: {
      const { item, data } = payload;
      draft.modalListCouriers[item] = data;
      break;
    }

    case UPDATE_MODAL_DELETE: {
      const { item, data } = payload;
      draft.modalDelete[item] = data;
      break;
    }

    case UPDATE_LOAD_MODAL_PRODUCTS: {
      draft.isLoadModalProduct = payload;
      break;
    }

    case UPDATE_MODAL_STREET: {
      const { item, data } = payload;
      draft.modalStreet[item] = data;
      break;
    }

    default:
      break;
  }
}, initialState);
