import produce from "immer";
import { isArray, findIndex } from "lodash";

import { LOGOUT } from "../constants/session";
import { RADIO_SCHEME_PROMOCODE_OR_PROMOACTION } from "constants/schemes";

import {
  UPDATE_SETTINGS_DATA,
  UPDATE_SETTINGS_CITIES_LIST,
  UPDATE_SETTINGS_KITCHENS_LIST,
  UPDATE_SETTINGS_LOCATIONS_LIST,
  UPDATE_SETTINGS_ORDER_STATUSES,
  UPDATE_SETTINGS_ORDER_TYPES,
  UPDATE_SETTINGS_ITEMS_STATUSES,
  UPDATE_SETTINGS_PRODUCT_CATEGORIES,
  UPDATE_SETTINGS_PRODUCT_TYPES,
  UPDATE_SETTINGS_ROLES_LIST,
  UPDATE_KITCHEN_FORM,
  UPDATE_KITCHEN_FORM_SHIFT_FIRST,
  UPDATE_KITCHEN_FORM_SHIFT_LAST,
  SET_KITCHEN,
  UPDATE_SETTINGS_RESTAURANS_LIST,
  UPDATE_RESTAURANT_FORM,
  UPDATE_CITY_FORM,
  CLEANING_NEW_FORM,
  UPDATE_LOCATION_FORM,
  GET_LOCATION,
  GET_CITY,
  SET_RESTORANT,
  UPDATE_ROLE_FORM,
  GET_ROLE,
  UPDATE_ROLE_FORM_PAGES,
  UPDATE_FORM_PROMOTION,
  UPDATE_FORM_PROMOTION_WEEK,
  UPDATE_FORM_PROMOTION_WINDOW,
  GET_PROMOTION,
  GET_PROMOTIO_ITEM,
  GET_PROMOCODE,
  UPDATE_FORM_PROMOCODE_WINDOW,
  UPDATE_FORM_PROMOCODE,
  GET_PROMOCODE_ITEM,
  UPDATE_FORM_PROMOCODE_ARRAY,
  UPDATE_FORM_RESOURCE,
  GET_RESOURCE_LIST,
  GET_RESOURCE_ID,
  GET_PAYMENT_LIST,
  UPDATE_PAYMENT_FORM,
  GET_PAYMENT_ID,
  DELETE_ONE_FIELD_PROMOCODE,
  ADD_ONE_FIELD_PROMOCODE,
  UPDATE_ONE_FIELD_CODE_PROMOCODE,
  ADD_ONE_FIELD_CODE_PROMOCODE,
  DELETE_ONE_FIELD_CODE_PROMOCODE,
  UPDATE_FORM_PICKUP,
  GET_FORM_PICKUP,
  DELETE_CITY_BY_ID,
  UPDATE_CITY_STREET_BY_ID,
  ADD_STREET_BY_CITY,
  UPDATE_FORM_TYPE_OF_DELIVERY,
  GET_TYPES_OF_DELIVERY_LIST,
  GET_DELIVERY_ID,
  UPDATE_FORM_REASON,
  GET_REASONS_LIST,
  GET_REASON_ID,
  CLEANING_NEW_FORM_OBJ,

  GET_BUG_REPORT_ALL,
  UPDATE_BUG_REPORT_LOAD_PAGE,
  UPDATE_BUG_REPORT_LOAD_MODAL,
  SELECT_BUG_REPORT_TO_VIEW,

  GET_COMPLAINT_ALL,
  UPDATE_COMPLAINT_LOAD_PAGE,
  UPDATE_COMPLAINT_LOAD_MODAL,
  GET_COMPLAINT_ONE,
  UPDATE_COMPLAINT_FORM,
  COMPLAINT_FORM_CLEAN,
  GET_COMPLAINT_ALL_SOURCES,
  UPDATE_COMPLAINT_LOAD_PAGE_SOURCES,
  UPDATE_COMPLAINT_LOAD_MODAL_SOURCES,
  GET_COMPLAINT_ONE_SOURCES,
  UPDATE_COMPLAINT_FORM_SOURCES,
  COMPLAINT_FORM_CLEAN_SOURCES,

  UPDATE_SETTINGS_PRODUCT_CATEGORIES_FORM,
  GET_ONE_SETTINGS_PRODUCT_CATEGORIE,
  UPDATE_TELESTAT_FORM,
  GET_TELESTATE_FORM,
  GET_TRANSPORT,
  GET_SETTINGS_CASHIER,
  GET_SETTINGS_METOD_PAYMENTS,
  SET_SETTINGS_POLIGONES
} from "../constants/settings";

const initialState = {
  cities: [],
  editCity: {
    id: null,
    uuid: "",
    sync_id: "",
    name: "",
    latitude: "",
    longitude: "",
    parent_id: 0,
    type: 1,
    status: 1,
    parse_type: 0,
    streets: [],
  },
  kitchens: null,
  editKitchens: {
    title: "",
    address: "",
    phone: "",
    city_sync_id: "",
    latitude: "",
    longitude: "",
    time_open: "",
    time_close: "",
    status: 0,
    pickup: 0,
    telegram_bot_name: "",
    telegram_bot_token: "",
    create_cash_checks_to: "",
    create_cashless_checks_to: "",
    shifts: [
      {
        shift: 1,
        cashalot_numfiscal_shift: "",
        cashalot_certificate_shift: "",
        cashalot_private_key_shift: "",
        cashalot_password_shift: "",
        cashier_name: "",
        status: 1
      },
      {
        shift: 2,
        cashalot_numfiscal_shift: "",
        cashalot_certificate_shift: "",
        cashalot_private_key_shift: "",
        cashalot_password_shift: "",
        cashier_name: "",
        status: 1
      }
    ],
  },
  locations: null,
  editLocation: {
    id: null,
    delivery_terminal_id: "",
    restaurant: "",
    name: "",
    city_sync_id: "",
    street: "",
    house_number: "",
    kitchen_code: "",
  },
  clientSources: null,
  orderStatuses: null,
  orderTypes: null,
  itemStatuses: null,
  productCategories: null,
  productCategoriesForm: {
    sync_id: "",
    name: "",
    restaurant: "",
    status: 1,
    cutlery_sets: null,
    take_part_promotions_discounts: 1,
    main_category: "other",
    sort: 1,
  },
  productTypes: null,
  roles: null,
  editRole: {
    title: "",
    pages: [],
  },
  restaurants: null,
  editRestaurant: {
    name: "",
    logo: "",
    status: "1",
    logo_path: "",
    order_review_sms_text: "",
    cart_notify_message: "",
    cart_notify_btn_name: "",
    cart_notify_btn_link: "",
    cart_notify_delay: 3,
    cart_notify_image: "",
    sale_employee_delivery: 50,
    sale_employee_pickup: 50,
  },
  promotions: [],
  promotionsEdit: {
    isOpen: false,
    isLoad: false,
    isLoadEdit: false,
    data: {
      id: null,
      type: '',
      name: '',
      description: '',
      product_count: '',
      status: 1,
      product_category_id: '',
      city_id: '',
      time_frame_type: 'days_of_the_week',
      isActions: 0,
      roles: [],
      sale: "50",
      product_categories: [],
      period: [
        {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        }
      ],
      days_of_the_week: [],
      restaurants: {},
    }
  },
  promocodes: [],
  promocodesEdit: {
    isOpen: false,
    isLoad: false,
    isLoadEdit: false,
    data: {
      id: null,
      promo_type: RADIO_SCHEME_PROMOCODE_OR_PROMOACTION[0].value,
      daily_usage: 0,
      title: '',
      status: 1,
      description: '',
      perpetual: "0",
      free_product: '',
      free_product_price: 0.01,
      restaurant: '',
      type: '',
      discount: '',
      code: '',
      min_order_amount: '',
      exclude_categories: [],
      exclude_products: [],
      generate_codes: "",
      codes: [],
      period: [
        {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        }
      ],
    }
  },
  resource: [],
  resourceEdit: {
    id: null,
    name: '',
    status: 1,
  },
  payment: [],
  paymentEditForm: {
    id: null,
    title: "",
    status: "1",
    free: "0",
    is_discount: "0",
  },
  pickupForm: {
    sale_client: 20,
    status_client: 1,
    category_exclusion_client: [],
    sale_employee: 50,
    status_employee: 1,
    category_exclusion_employee: []
  },
  typesOfDelivery: {
    list: []
  },
  editTypeOfDelivery: {
    id: null,
    name: "",
    code: "",
    status: 1,
    payments: [],
    partner_price: 0,
  },
  cutlery: {
    list: [],
  },
  blackList: {
    reasonsList: [],
    editFormReason: {
      id: null,
      title: '',
    }
  },
  bugReports: {
    list: [],
    isLoadPage: false,
    isLoadModal: false,
    selectedBugReport: null,
  },
  complaints: {
    complaint: {
      isLoadPage: false,
      isLoadModal: false,
      list: [],
      form: {
        name: "",
        status: 1,
      },
    },
    resource: {
      isLoadPage: false,
      isLoadModal: false,
      list: [],
      form: {
        name: "",
        status: 1,
      },
    }
  },
  telestat: {
    link: "",
    token: "",
    audio_link: "",
  },
  transport: {
    list: [],
  },
  cashier: {
    list: [],
  },
  metodPayments: {
    list: [],
  },
  poligones: [],
};

export default produce((draft, action) => {
  const { payload } = action;

  switch (action.type) {
    case UPDATE_SETTINGS_DATA: {
      draft.cities = payload.cities ?? [];
      draft.kitchens = payload.kitchens ?? [];
      draft.locations = payload.locations ?? [];
      draft.payment = payload.payment ?? [];
      draft.orderStatuses = payload.orderStatuses ?? [];
      draft.orderTypes = payload.orderTypes ?? [];
      draft.itemStatuses = payload.itemStatuses ?? [];
      draft.productCategories = payload.productCategories ?? [];
      draft.productTypes = payload.productTypes ?? [];
      draft.roles = payload.roles ?? [];
      draft.restaurants = payload.restaurants ?? [];
      draft.promotions = payload.promotions ?? [];
      draft.resource = payload.resource ?? [];
      draft.typesOfDelivery.list = payload.typesOfDelivery ?? [];
      draft.blackList.reasonsList = payload.reasonList ?? [];
      draft.complaints.complaint.list = payload.complaint ?? [];
      draft.complaints.resource.list = payload.sources ?? [];
      draft.cutlery.list = payload.cutlery ?? [];
      draft.telestat = payload.telestat ?? {};
      break;
    }

    case SET_SETTINGS_POLIGONES: {
      draft.poligones = payload;
    }

    case GET_SETTINGS_CASHIER: {
      draft.cashier.list = payload;
      break;
    }

    case GET_SETTINGS_METOD_PAYMENTS: {
      draft.metodPayments.list = payload;
      break;
    }

    case GET_TRANSPORT: {
      draft.transport.list = payload;
      break;
    }

    // telestat
    case UPDATE_TELESTAT_FORM: {
      draft.telestat[payload.item] = payload.data;
      break;
    }
    case GET_TELESTATE_FORM: {
      draft.telestat = payload;
      break;
    }
    // end telestat

    // bug report
    case GET_BUG_REPORT_ALL: {
      draft.bugReports.list = payload;
      break;
    }

    case UPDATE_BUG_REPORT_LOAD_PAGE: {
      draft.bugReports.isLoadPage = payload;
      break;
    }

    case UPDATE_BUG_REPORT_LOAD_MODAL: {
      draft.bugReports.isLoadModal = payload;
      break;
    }

    case SELECT_BUG_REPORT_TO_VIEW: {
      draft.bugReports.selectedBugReport = payload;
      break;
    }

    // complaints
    case COMPLAINT_FORM_CLEAN: {
      draft.complaints.complaint.form = initialState.complaints.complaint.form;
      break;
    }
    case UPDATE_COMPLAINT_FORM: {
      draft.complaints.complaint.form[payload.item] = payload.data;
      break;
    }
    case GET_COMPLAINT_ALL: {
      draft.complaints.complaint.list = payload;
      break;
    }
    case GET_COMPLAINT_ONE: {
      draft.complaints.complaint.form = payload;
      break;
    }
    case UPDATE_COMPLAINT_LOAD_PAGE: {
      draft.complaints.complaint.isLoadPage = payload;
      break;
    }
    case UPDATE_COMPLAINT_LOAD_MODAL: {
      draft.complaints.complaint.isLoadModal = payload;
      break;
    }
    // resurces
    case COMPLAINT_FORM_CLEAN_SOURCES: {
      draft.complaints.resource.form = initialState.complaints.resource.form;
      break;
    }
    case UPDATE_COMPLAINT_FORM_SOURCES: {
      draft.complaints.resource.form[payload.item] = payload.data;
      break;
    }
    case GET_COMPLAINT_ALL_SOURCES: {
      draft.complaints.resource.list = payload;
      break;
    }
    case GET_COMPLAINT_ONE_SOURCES: {
      draft.complaints.resource.form = payload;
      break;
    }
    case UPDATE_COMPLAINT_LOAD_PAGE_SOURCES: {
      draft.complaints.resource.isLoadPage = payload;
      break;
    }
    case UPDATE_COMPLAINT_LOAD_MODAL_SOURCES: {
      draft.complaints.resource.isLoadModal = payload;
      break;
    }
    // end complaints

    // reason
    case UPDATE_FORM_REASON: {
      draft.blackList.editFormReason[payload.item] = payload.data;
      break;
    }

    case GET_REASONS_LIST: {
      draft.blackList.reasonsList = payload;
      break;
    }

    case GET_REASON_ID: {
      draft.blackList.editFormReason = payload;
      break;
    }

    case CLEANING_NEW_FORM_OBJ: {
      draft[payload.obj][payload.name] = initialState[payload.obj][payload.name];
      break;
    }

    // types of delivery
    case UPDATE_FORM_TYPE_OF_DELIVERY: {
      draft.editTypeOfDelivery[payload.item] = payload.data;
      break;
    }
    case GET_TYPES_OF_DELIVERY_LIST: {
      draft.typesOfDelivery.list = payload;
      break;
    }
    case GET_DELIVERY_ID: {
      draft.editTypeOfDelivery = payload;
      break;
    }
    // end types of delivery

    case GET_FORM_PICKUP: {
      draft.pickupForm = payload;
      break;
    }

    case UPDATE_FORM_PICKUP: {
      draft.pickupForm[payload.item] = payload.data;
      break;
    }

    case GET_PAYMENT_ID: {
      draft.paymentEditForm = { ...draft.paymentEditForm, ...payload };
      break;
    }

    case UPDATE_PAYMENT_FORM: {
      draft.paymentEditForm[payload.item] = payload.data;
      break;
    }

    case GET_PAYMENT_LIST: {
      draft.payment = payload;
      break;
    }

    case GET_RESOURCE_ID: {
      draft.resourceEdit = payload;
      break;
    }

    case GET_RESOURCE_LIST: {
      draft.resource = payload;
      break;
    }

    case UPDATE_FORM_RESOURCE: {
      draft.resourceEdit[payload.item] = payload.data;
      break;
    }

    case DELETE_ONE_FIELD_CODE_PROMOCODE: {
      draft.promocodesEdit.data.codes.splice(payload.index, 1);
      break;
    }

    case UPDATE_ONE_FIELD_CODE_PROMOCODE: {
      draft.promocodesEdit.data.codes[payload.index].code = payload.value;
      break;
    }

    case ADD_ONE_FIELD_CODE_PROMOCODE: {
      draft.promocodesEdit.data.codes.push({ code: "", status: 1 });
      break;
    }

    case ADD_ONE_FIELD_PROMOCODE: {
      draft.promocodesEdit.data[payload.item] = payload.data;
      break;
    }

    case DELETE_ONE_FIELD_PROMOCODE: {
      delete draft.promocodesEdit.data[payload.data];
      break;
    }

    case GET_PROMOCODE: {
      draft.promocodes = payload;
      break;
    }

    case GET_PROMOCODE_ITEM: {
      const { free_product_price: fallbackFreeProductPrice } = initialState.promocodesEdit.data;
      draft.promocodesEdit.data = {
        ...draft.promocodesEdit.data,
        ...payload,
        free_product_price: payload.free_product_price ?? fallbackFreeProductPrice,
        daily_usage: payload.daily_usage ?? 0,
      };
      break;
    }

    case UPDATE_FORM_PROMOCODE_WINDOW: {
      draft.promocodesEdit[payload.item] = payload.data;

      if (payload.isNew) {
        draft.promocodesEdit.data = initialState.promocodesEdit.data;
      }
      break;
    }

    case UPDATE_FORM_PROMOCODE_ARRAY: {
      draft.promocodesEdit.data[payload.item] = payload.data;
      break;
    }

    case UPDATE_FORM_PROMOCODE: {
      draft.promocodesEdit.data[payload.item] = payload.data;
      break;
    }

    case GET_PROMOTIO_ITEM: {
      draft.promotionsEdit.data = {
        ...draft.promotionsEdit.data,
        ...payload
      };
      break;
    }

    case GET_PROMOTION: {
      draft.promotions = payload;
      break;
    }

    case UPDATE_FORM_PROMOTION_WEEK: {
      let index = draft.promotionsEdit.data.days_of_the_week.findIndex((el) => el === payload);

      if (index === 0 || index > 0) {
        draft.promotionsEdit.data.days_of_the_week.splice(index, 1);
      } else {
        draft.promotionsEdit.data.days_of_the_week.push(payload);
      }

      break;
    }

    case UPDATE_FORM_PROMOTION_WINDOW: {
      draft.promotionsEdit[payload.item] = payload.data;

      if (payload.isNew) {
        draft.promotionsEdit.data = initialState.promotionsEdit.data;
      }
      break;
    }

    case UPDATE_FORM_PROMOTION: {
      draft.promotionsEdit.data[payload.item] = payload.data;
      break;
    }

    case UPDATE_ROLE_FORM_PAGES: {
      let index = draft.editRole.pages.findIndex((e) => e.value === payload.item);

      draft.editRole.pages[index].pages = payload.data;
      break;
    }

    case GET_ROLE: {
      draft.editRole = payload;
      break;
    }

    case UPDATE_ROLE_FORM: {
      draft.editRole[payload.item] = payload.data;
      break;
    }

    case GET_LOCATION: {
      draft.editLocation = { ...draft.editLocation, ...payload };
      break;
    }

    case UPDATE_LOCATION_FORM: {
      draft.editLocation[payload.item] = payload.data;
      break;
    }

    case CLEANING_NEW_FORM: {
      draft[payload.item] = initialState[payload.item];
      break;
    }

    case GET_CITY: {
      draft.editCity = { ...draft.editCity, ...payload };
      break;
    }

    case UPDATE_CITY_FORM: {
      draft.editCity[payload.item] = payload.data;
      break;
    }

    case DELETE_CITY_BY_ID: {
      if (isArray(draft.editCity.streets)) {
        draft.editCity.streets.splice(payload, 1);
      }

      break;
    }

    case UPDATE_CITY_STREET_BY_ID: {
      if (isArray(draft.editCity.streets)) {
        draft.editCity.streets[payload.id].street = payload.value;
      }

      break;
    }

    case ADD_STREET_BY_CITY: {
      draft.editCity.streets.unshift({ street: payload })
      break;
    }

    case SET_RESTORANT: {
      draft.editRestaurant = payload;
      break;
    }

    case UPDATE_RESTAURANT_FORM: {
      draft.editRestaurant[payload.item] = payload.data;
      break;
    }

    case SET_KITCHEN: {
      const { data, updateKitchenStore } = payload;
      if (updateKitchenStore && isArray(draft.kitchens)) {
        const { id } = data; // kitchenId
        const index = findIndex(draft.kitchens, { id });

        if (index === -1) {
          // kitchen not found, add new object
          draft.kitchens.push(data);
        } else {
          draft.kitchens.splice(index, 1, data); // update existing kitchen with new data
        }
      }

      draft.editKitchens = { ...draft.editKitchens, ...data };
      break;
    }

    case UPDATE_KITCHEN_FORM: {
      draft.editKitchens[payload.item] = payload.data;
      break;
    }

    case UPDATE_KITCHEN_FORM_SHIFT_FIRST: {
      draft.editKitchens.shifts[0][payload.item] = payload.data;
      break;
    }

    case UPDATE_KITCHEN_FORM_SHIFT_LAST: {
      draft.editKitchens.shifts[1][payload.item] = payload.data;
      break;
    }

    case UPDATE_SETTINGS_CITIES_LIST: {
      draft.cities = payload;
      break;
    }

    case UPDATE_SETTINGS_RESTAURANS_LIST: {
      draft.restaurants = payload;
      break;
    }

    case UPDATE_SETTINGS_KITCHENS_LIST: {
      draft.kitchens = payload;
      break;
    }

    case UPDATE_SETTINGS_LOCATIONS_LIST: {
      draft.locations = payload;
      break;
    }

    case UPDATE_SETTINGS_ORDER_STATUSES: {
      draft.orderStatuses = payload;
      break;
    }

    case UPDATE_SETTINGS_ORDER_TYPES: {
      draft.orderTypes = payload;
      break;
    }

    case UPDATE_SETTINGS_ITEMS_STATUSES: {
      draft.itemStatuses = payload;
      break;
    }

    case UPDATE_SETTINGS_PRODUCT_CATEGORIES: {
      draft.productCategories = payload;
      break;
    }

    case UPDATE_SETTINGS_PRODUCT_CATEGORIES_FORM: {
      draft.productCategoriesForm[payload.item] = payload.data;
      break;
    }

    case GET_ONE_SETTINGS_PRODUCT_CATEGORIE: {
      draft.productCategoriesForm = payload;
      break;
    }

    case UPDATE_SETTINGS_PRODUCT_TYPES: {
      draft.productTypes = payload;
      break;
    }

    case UPDATE_SETTINGS_ROLES_LIST: {
      draft.roles = payload;
      break;
    }

    case LOGOUT: {
      draft.cities = null;
      draft.kitchens = null;
      draft.roles = null;
      draft.productCategories = null;
      draft.productTypes = null;
      draft.statuses = null;
      draft.timeTypes = null;
      draft.itemsTypes = null;
      break;
    }

    default:
      break;
  }
}, initialState);
