import produce from 'immer';

import {
    GET_CLIENTS_SUCCESS,
    GET_SOURCES_SUCCESS,
    GET_CLIENTS_EXTENSIVE,
    UPDATE_CLIENT_SEARCH_FORM,
    GET_CLIENTS_SEARCH,
    UPDATE_FORM_CLIENTS,
    UPDATE_FORM_ADD_CLIENT,
    CLEAR_FORM_ADD_CLIENT,
    UPATE_FORM_CLIENT_BONUS,
    UPATE_FORM_CLIENT_BONUS_CLEAR,
    UPATE_FORM_CLIENT_BONUS_WRITE,
    CALLS_UPDATE_ISLOAD,
    CALLS_UPDATE_FILTER,
    CALLS_GET_LIST_CALLS,
} from '../constants/clients';

import { LOGOUT } from '../constants/session';

const initialState = {
    list: [],
    sources: [],
    extensive: {
        id: null,
        name: "",
        phone: null,
        source: "",
        is_regular: null,
        sex: null,
        birthday: "",
        blocking_reasons: [],
        comment: "",
        wallet: {
            balances: []
        },
    },
    calls: {
        list: [],
        filter: {
            date: [
                {
                    startDate: new Date(),
                    endDate: new Date(),
                    key: "selection",
                }
            ]
        },
        isLoad: false,
    },
    searchForm: {
        phone: "",
        name: "",
    },
    addClient: {
        name: "",
        phone: null,
        source: "",
        sex: null,
        comment: "",
        birthday: "",
    },
    searchClients: {
        data: [],
        meta: {
            current_page: 1,
            last_page: 1,
        },
    },
    bonusClient: {
        restaurantId: null,
        value: 0,
        comment: "",
        isMinus: false,
    }
};

export default produce((draft, action) => {
    const { payload } = action;

    switch (action.type) {
        // calls
        case CALLS_UPDATE_ISLOAD: {
            draft.calls.isLoad = payload;
            break;
        }
        case CALLS_UPDATE_FILTER: {
            draft.calls.filter[payload.item] = payload.data;
            break;
        }
        case CALLS_GET_LIST_CALLS: {
            draft.calls.list = payload;
            break;
        }
        // end calls

        case GET_CLIENTS_SUCCESS: {
            draft.list = payload.data;
            break;
        }

        case UPATE_FORM_CLIENT_BONUS_WRITE:
            draft.extensive.wallet.balances.find((item) => item.restaurant_id === payload.restaurantId).writes_off = payload.value;
            break;

        case CLEAR_FORM_ADD_CLIENT: {
            draft.addClient = initialState.addClient;
            break;
        }

        case GET_SOURCES_SUCCESS: {
            draft.sources = payload.data;
            break;
        }

        case GET_CLIENTS_EXTENSIVE: {
            draft.extensive = payload;
            break;
        }

        case LOGOUT: {
            draft.list = [];
            break;
        }

        case UPDATE_CLIENT_SEARCH_FORM:
            draft.searchForm[payload.item] = payload.data;
            break;

        case GET_CLIENTS_SEARCH: {
            draft.searchClients = payload;
            break;
        }

        case UPDATE_FORM_CLIENTS:
            draft.extensive[payload.item] = payload.data;
            break;

        case UPDATE_FORM_ADD_CLIENT:
            draft.addClient[payload.item] = payload.data;
            break;

        case UPATE_FORM_CLIENT_BONUS:
            draft.bonusClient[payload.item] = payload.data;
            break;

        case UPATE_FORM_CLIENT_BONUS_CLEAR:
            draft.bonusClient = initialState.bonusClient;
            break;


        default:
            break;
    }
}, initialState);
