import { isArray } from "lodash";

export const isActiveTabPosition = (data, isSelfPickup = false) => {
    const { client, address, kitchen_code, restaurant } = data;

    let isDisabled = true;
    let isClient = !!client.id;
    let isAddress = !!address.street && !!address.house_number && !!address.city_sync_id;
    let isKitchen = !!kitchen_code;
    let isRestorant = !!restaurant && restaurant != '-';

    isDisabled = !(isClient && (isSelfPickup ? true : isAddress) && isKitchen && isRestorant);

    return isDisabled;
}

export const isActiveTabPayment = (data) => {
    const { items } = data;

    let isDisabled = true;
    let isItems = isArray(items) && !!items.length;

    isDisabled = !isItems;

    return isDisabled;
}