import { useEffect, useState } from "react";

import ButtonIcon from "components/ui-kit/ButtonIcon";
import Table from "components/ui-kit/Table";
import { LoadingCircl } from "components/ui-kit/LoadingScreen/LoadingCircl";
import { EditPromotion } from "./EditPromotion";
import ModalDelete from "components/modals/ModalDelete";
import { LoadingModal } from "components/loadingOrder/loading";

import AddIcon from "@mui/icons-material/Add";
import styles from "../scss/settings.module.scss";

import { tablePromotions } from "constants/tables";

const Promotions = ({
    //state
    roles,
    cities,
    restaurants,
    products,
    categories,
    promotions,
    promotionsEdit,
    isLoad,
    isOpenEdit,
    isLoadEdit,
    isOpenModalDelete,
    isLoadModalDelete,
    //dispatch
    updateFormPromotion,
    updateFormPromotionWeek,
    updateFormPromotionWindow,
    getPromotion,
    getPromotionId,
    setCreatePromotionDefaultValues,
    createPromotion,
    updatePromotion,
    deletePromotion,
    updateModalDelete,
    getProducts,
}) => {
    const [idDeletePromotion, setIdDeletePromotion] = useState(undefined);

    useEffect(() => {
        getPromotion();
    }, []);

    const handlerCreatePromotion = () => {
        setCreatePromotionDefaultValues();
        updateFormPromotionWindow('isOpen', true, true);
    }

    const handlerEditPromotions = ({ id }) => {
        getPromotionId(id);
        updateFormPromotionWindow('isOpen', true);
    }

    const handleClosePromotions = () => {
        updateFormPromotionWindow('isOpen', false);
    }

    const handleSubmitСreate = () => {
        createPromotion();
    }

    const handleSubmitUpdate = () => {
        updatePromotion();
    }

    const onDelete = ({ id }) => {
        updateModalDelete('isOpen', true);
        setIdDeletePromotion(id);
    }

    const handleDeletePromotions = () => {
        deletePromotion(idDeletePromotion);
    }

    return (
        <div>
            <div className={styles.wr_head}>
                <ButtonIcon
                    onClick={handlerCreatePromotion}
                    icon={<AddIcon />}
                    positionIcon="left"
                />
            </div>
            <div className={`${styles.row_mrt} ${styles.d_max_1023}`}>
                <div className={`${styles.wr_row}`}>
                    {
                        !isLoad ? (
                            promotions.length > 0 ? (
                                <Table
                                    onClick={handlerEditPromotions}
                                    onDelete={onDelete}
                                    data={promotions}
                                    scheme={tablePromotions}
                                />
                            ) : (
                                <div className={styles.text_info}>Пусто... Немає акцій</div>
                            )
                        ) : (
                            <LoadingCircl />
                        )
                    }
                </div>
                {
                    isOpenEdit && (
                        <div className={`${styles.row_mrt}`}>
                            <EditPromotion
                                roles={roles}
                                cities={cities}
                                categories={categories}
                                restaurants={restaurants}
                                promotions={promotions}
                                products={products}
                                promotionsEdit={promotionsEdit}
                                handleSubmitClose={handleClosePromotions}
                                updateFormPromotion={updateFormPromotion}
                                updateFormPromotionWeek={updateFormPromotionWeek}
                                handleSubmitСreate={handleSubmitСreate}
                                handleSubmitUpdate={handleSubmitUpdate}
                                getProducts={getProducts}
                            />
                            {
                                isLoadEdit && (
                                    <LoadingModal />
                                )
                            }
                        </div>
                    )
                }
            </div>

            <ModalDelete
                title={'Видалити'}
                text={`Ви підтверджуєте видалення ?`}
                isOpen={isOpenModalDelete}
                isLoad={isLoadModalDelete}
                funDelete={() => handleDeletePromotions()}
                updateModalDelete={updateModalDelete}
            />
        </div>
    )
}

export default Promotions;