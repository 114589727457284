import { useState, useEffect, useMemo } from "react";
import moment from "moment";

import styled, { useTheme } from "styled-components";
import { isArray, padStart } from "lodash";

import { renderIcon } from "utils/helpers/typeKitchen";
import { getTimeFlag } from "utils/helpers/getTimeFlag";

import { LoadingModal } from "components/loadingOrder/loading";

import PersonIcon from '@mui/icons-material/Person';

const ItemProduct = ({ data }) => {
  return (
    <WrOrder>
      <OrderMain>
        {
          data.quantity > 1 && (
            <OrderImg>
              {data.quantity}
            </OrderImg>
          )
        }
        <OrderInfo>
          <OrderName>{data.product?.title_ua || ''}</OrderName>
          <ItemsComponents>
            {
              isArray(data.additions) && data.additions.map((itemDop, index) => {
                return (
                  <ItemComponent key={index}>{itemDop.addition.name} - {itemDop.quantity} шт. {((data.additions.length - 1) != index) && ','}</ItemComponent>
                )
              })
            }
          </ItemsComponents>
        </OrderInfo>
      </OrderMain>
      {
        data.comment && (
          <>
            <TitleText>Коментар:</TitleText>
            <TextOrder>{data.comment ? data.comment : '-----'}</TextOrder></>
        )
      }
    </WrOrder>
  )
}

export const ItemOrder = ({
  isPrepared = false,
  data,
  idLoad = null,
  handlerPatchStatus = () => { },
}) => {
  if (data.items[0]?.product === null)
    return (<></>);

  const theme = useTheme();
  const [timer, setTimer] = useState('00:00:00');

  const typeIconkitchen = renderIcon(data?.restaurant);

  const timeCook = getTimeFlag(data, 'delivery_till');
  const startCooking = getTimeFlag(data, 'start_cooking');

  const startCookingMoment = useMemo(() => {
    return !!startCooking?.time && moment(startCooking.time, 'DD/MM HH:mm');
  }, [startCooking?.time]);

  useEffect(() => {
    if (!(data?.status === 'cooking' && startCookingMoment))
      return;

    const interval = setInterval(function timeInterval() {
      const duration = moment.duration(moment().diff(startCookingMoment, 'ms'));
      setTimer(() => {
        return [
          Math.trunc(duration.asHours()),
          duration.minutes(),
          duration.seconds()
        ].map((item) => padStart(item.toString(), 2, '0')).join(':')
      });
      return timeInterval;
    }(), 1000);

    return () => clearInterval(interval);

  }, [data?.status, startCookingMoment]);

  return (
    <Wrapper
      theme={theme}
      onClick={() => handlerPatchStatus(data)}
    >
      <Head>
        <View>
          {
            typeIconkitchen && <ImgKit src={typeIconkitchen} />
          }
          <Order>№ <OrderItem>{data?.id},</OrderItem></Order>
          {
            data?.persons && (
              <Pers>
                <PersonIcon />
                {data.persons}
              </Pers>
            )
          }
        </View>

        {
          isPrepared ? (
            <Done>Готово</Done>
          ) : (
            <TimeDiv>
              <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                {
                  (['pending', 'pending_confirmed'].includes(data.status)) && (
                    <span>Доставити до: </span>
                  )
                }
                <Time bg={timeCook.class}>{timeCook.time}</Time>
              </div>
              {(data.status === 'cooking') && (
                <div style={{ padding: '4px 0 4px 0' }}>
                  <Time>{timer}</Time>
                </div>
              )}
              {
                (['pending', 'pending_confirmed'].includes(data.status)) && (
                  <TimeDos><span>Початок готування: </span><b>{data.time_flags?.start_cooking}</b></TimeDos>
                )
              }
            </TimeDiv>
          )
        }

      </Head>

      {
        isArray(data?.items) && data.items.map((item) => (
          <ItemProduct
            key={item.id}
            data={item}
          />
        ))
      }

      {data?.comment_to_courier && (
        <CourierCommentWrapper>
          <TitleText as="span">Коментар кур'єру:</TitleText>
          <TextOrder as="span">{data.comment_to_courier}</TextOrder>
        </CourierCommentWrapper>
      )}

      {
        (data.id == idLoad) && (
          <LoadingModal />
        )
      }

    </Wrapper>
  )
}

const Done = styled.div`
    text-align: center;
    padding: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    background: green;
    color: #fff;
    font-weight: 400;
    font-size: 14px;
    margin-left: auto;
    
    @media (max-width: 1200px) {
        font-size: 18px;
    }
`;

const TimeDiv = styled.div`
    margin-left: auto;
    display: flex;
    flex-direction: column;
    aling-items: flex-end;
`;

const TimeDos = styled.div`
    margin-top: 10px;
`;

const Pers = styled.div`
    display: flex;
    align-items: center;
    margin-left: 10px;
`;

const Wrapper = styled.div`
    padding: 10px;
    border-radius: 8px;
    background: #fff;
    transition: 0.4s;
    position: relative;
    border: 2px solid #eda240;
    &:hover {
        background: ${(p) => p.theme.colorEC};
    }
`;

const CourierCommentWrapper = styled.div`
    padding: 10px 10px 0 10px;
`;

const Head = styled.div`
    display: flex;
    gap: 16px;
    align-items: flex-start;
`;

const Order = styled.div`
    font-weight: 400;
    font-size: 14px;
    @media (max-width: 1200px) {
        font-size: 18px;
    }
`;

const OrderItem = styled.span`
    font-weight: 600;
`;

const View = styled.div`
    display: flex;
    align-items: center;
`;

const ImgKit = styled.img`
    display: flex;
    align-items: center;
    margin-right: 10px;
    max-width: 24px;
`;

const Time = styled.div`
    text-align: center;
    padding: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    background: ${(p) => p.bg};
    color: #000;
    font-weight: 400;
    font-size: 14px;
    margin-left: auto;

    @media (max-width: 1200px) {
        font-size: 18px;
    }
 `;

const WrOrder = styled.div`
    margin: 0 -10px 0 -10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    &:not(:last-child) {
        border-bottom: 1px solid ${(p) => p.theme.colorEC};
        padding-bottom: 10px;
    }
`;

const OrderMain = styled.div`
    display: flex;
    align-items: flex-start;
`;

const OrderImg = styled.div`
    min-width: 40px; 
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 500;
    background: #483f3f;
`;

const OrderInfo = styled.div`
    margin-left: 10px;
    width: calc(100% - 40px);
`;

const OrderName = styled.div`
    color: ${(p) => p.theme.color33};
    font-weight: 500;
    font-size: 16px;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    max-width: 100%;

    @media (max-width: 1200px) {
        font-size: 20px;
    }
`;

const ItemsComponents = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const ItemComponent = styled.span`
    padding: 0px 5px 0 0;
    color: ${(p) => p.theme.color5C};
    font-size: 14px;

    @media (max-width: 1200px) {
        font-size: 18px;
    }
`;

const TitleText = styled.div`
    color: blue;
    font-weight: 500;
    margin: 10px 0 0 0;
    font-size: 14px;
    
    @media (max-width: 1200px) {
        font-size: 18px;
    }
`;

const TextOrder = styled.div`
    margin: 5px 0 0 0;
    padding-left: 10px;
    font-weight: 500;
    font-size: 14px;
    color: blue;

    @media (max-width: 1200px) {
        font-size: 18px;
    }
`;