import React, { useState } from "react";
import styled, { useTheme } from "styled-components";

import Loader from "../Spiner";

const ButtonIcon = ({
  title = '',
  type = '',
  icon,
  onClick = () => { },
  disabled = false,
  colorBg = "",
  positionIcon = "right",
  width,
  isLoad = false,
  loaderProps,
  label = "",
  isActive = false,
  isMin = false,
  isModal = false
}) => {
  const [show, setShow] = useState(false);
  const theme = useTheme();
  colorBg = colorBg.length ? colorBg : theme.accentColor;

  const handleClick = () => {
    if (isModal) {
      if (!show) {
        setShow(true)
        return;
      }

      setShow(false)
      return;
    }

    onClick();
  }

  const handleYes = () => {
    setShow(false);
    onClick();
  }

  return (
    <WrapperBtn>
      <Wrapper
        onClick={handleClick}
        theme={theme}
        type={type}
        disabled={disabled}
        colorBg={colorBg}
        width={width}
        title={label}
        isActive={isActive}
        isMin={isMin}
      >
        {
          !!icon && positionIcon.includes('left') && <Icon>{icon}</Icon>
        }
        {
          title && <Title>{title}</Title>
        }
        {
          icon && positionIcon.includes('right') && <Icon>{icon}</Icon>
        }
        {
          isLoad && <Loader {...loaderProps} />
        }
      </Wrapper>
      {
        (isModal && show) && (
          <Modal className="nors">
            <div className="nors">Підтвердити ? </div>
            <RowTwoBtn className="nors">
              <Butt onClick={handleYes}>Так</Butt>
              <Butt onClick={() => setShow(false)}>Ні</Butt>
            </RowTwoBtn>
          </Modal>
        )
      }
    </WrapperBtn>
  );
};

const WrapperBtn = styled.div`
  position: relative;
  background: transparent;
`;

const Butt = styled.button`
  width: auto!important;
  height: auto!important;
  min-height: none!important;
  cursor: pointer;
  padding: 2px 4px;
  &:hover {
    background: green;
    color: #fff;
  }
`;

const RowTwoBtn = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
  margin-top: 4px;
`;

const Modal = styled.div`
  position: absolute;
  right: 120%;
  top: -10px;
  width: 140px;
  border-radius: 12px;
  background: #9D9D9D;
  padding: 10px;
  z-index: 20;
  color: #fff;
`;

const Wrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(p) => p.isMin ? "2px 10px" : "4px 15px"};
  min-height: ${(p) => p.isMin ? "30px" : "42px"};
  border-radius: 8px;
  color: ${(p) => p.theme.secondaryColor};
  background-color: ${(p) => p.isActive ? p.theme.proceedColor : p.colorBg};
  pointer-events: ${(p) => p.isActive ? "none" : "auto"};
  transition: background-color 200ms ease-in-out;
  width: ${(p) => p.width ? p.width : "100%"};
  position: relative;
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  &:hover {
    cursor: pointer;
    background-color: ${(p) => p.theme.proceedColor};
  }

  div:not(.nors) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #00000036;
  }
`;

const Title = styled.span`
font-family: 'Barlow', sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 110%;
  margin: 0 5px;
`;

const Icon = styled.span`
  color: ${(p) => p.theme.secondaryColor};
  height: 18px;
  width: 18px;
  svg {
    height: inherit;
    width: inherit;
  }
  color: inherit;
`;

export default ButtonIcon;
